<template>
  <div id="app">
    <!-- 全局布局和样式可以放在这里 -->
    <router-view />
    <!-- 当前路由匹配的组件将渲染在这里 -->
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* 全局样式 */
</style>
