import { createStore } from "vuex";

export default createStore({
  state: {
    groupId: null,
    wechatId: null,
    name: "",
    groupName: "",
  },
  mutations: {
    SET_GROUP_ID(state, id) {
      state.groupId = id;
    },
    SET_WECHAT_ID(state, id) {
      state.wechatId = id;
    },
    SET_NAME(state, name) {
      state.name = name;
    },
    SET_GROUP_NAME(state, groupName) {
      state.groupName = groupName;
    },
    SET_APPOINTMENT_INFO(state, { groupId, wechatId, name, groupName }) {
      state.groupId = groupId;
      state.wechatId = wechatId;
      state.name = name;
      state.groupName = groupName;
    },
  },
  actions: {
    setAppointmentInfo({ commit }, { groupId, wechatId, name, groupName }) {
      commit("SET_APPOINTMENT_INFO", { groupId, wechatId, name, groupName });
    },
  },

  getters: {
    appointmentInfo: (state) => ({
      groupId: state.groupId,
      wechatId: state.wechatId,
      name: state.name,
      groupName: state.groupName,
    }),
  },
});
