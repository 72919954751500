import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
// import "./styles/index.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

const app = createApp(App);

app.use(router);
app.use(ElementPlus);
app.use(store);
app.mount("#app");
